*{
  box-sizing: border-box;
  margin: 0;
  color: #313131;
}

a{
  text-decoration: none;
}

.mobile-img{
  display: none
}

.sidenav{
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  transition: 0.5s ease-in-out;
  background-color: white;
  z-index: 998;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}

.sidenav-open{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  transition: 0.5s ease-in-out;
  background-color: white;
  z-index: 998;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;

}

.sidenav-link{
  font-size: 32px;
  margin: 1rem 0;
}

.laura-btn{
  background-color: unset;
  border: 1px solid #313131;
  padding: 20px;
  height: 70px;
  width: 245px;
  max-width: 95vw;
  font-family: "bigmoore", serif; 
  font-weight: 400; 
  font-style:italic;
  font-size: 24px;
  transition: 0.3s ease-in-out;

}

.laura-btn:hover{
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: 0.3s ease-in-out;
}

#burger-menu{
  display: none;
}


h2{
  font-family: 'Sfizia';
  font-size: 64px;
  font-weight: 200;
  
}

nav a{
  font-family: "bigmoore", serif; 
  font-weight: 400; 
  font-style:italic;
  font-size: 22px;
  margin-left: 20px
}

nav a:hover, button:hover{
  cursor: pointer;
}

p{
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  margin: 1rem 0;
}

header{
  background-color: #A7B3B3;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 999;
  position: relative;
}

#initial-logo{
  height: 36px;
}

#logo-name{
  height: 28px;
}

/*Hero Section */
#hero{
  background-image: url('./Assets/hero.webp');
  width: 100vw;
  min-height: 600px;
  background-position: center;
  background-size: cover;
  height: 90vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}



/* small secction */
#elevate{
  
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0 5rem 0;
}

#elevate-mobile{
  display: none;
}

#elevate p, #elevate-mobile p, #sent-msg {
  width: 900px;
  max-width: 90vw;
  text-align: center;
  margin: 3rem 0;
  font-family: "bigmoore", serif; 
  font-weight: 400; 
  font-style: italic;
  font-size: 42px;
}

/* Design Services */
#services{
  background-color: #DFDFD1;
  min-height: 700px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 3rem;
}

#services h3{
  font-family: "commuters-sans", sans-serif; 
  font-weight: 400; 
  font-style: normal;
  text-transform: uppercase;
  margin: 20px 0;
}

.service-container{
  background-color: #DFDFD1;
  padding: 0 1rem;
  transition: 0.3s ease-in-out;

}

.service-container p{
  font-weight: 100;
  font-size: 18px;
}

.service-container:hover{
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

#brainteaser{
  height: 600px;
  width: 600px;
  object-fit: contain;
  object-position: center;
}

.accordion-open{
  height: 130px;
  overflow: hidden;
  transition: 0.3s ease-in-out;

}

.accordion-closed{
  height: 0;
  overflow: hidden;
  transition: 0.3s ease-in-out;

}

.chevron-inverted{
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
  height: 20px;
}

.chevron{
  height: 20px;
  transition: 0.3s ease-in-out;
}

/* Work with me */
#work-with-me{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
  margin: 4rem 0;
}

#work-with-me p{
  margin: 2rem 0;
  width: 900px;
  max-width: 90vw;
  text-align: center;
  line-height: 36px;

}

/* Gallery */
#gallery{
  display: flex;
  flex-wrap: wrap;
}

.width-25{
  width: 25%;
  object-fit: cover;
  object-position: center;
}

.width-30{
  width: 30%;
  object-fit: cover;
  object-position: center;
  transition: 0.5s ease-in-out;

}

.width-40{
  width: 45%;
  object-fit: cover;
  object-position: center;
  transition: 0.5s ease-in-out;

}

.width-30:hover, .width-40:hover{
  scale: 1.02;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

/* COntact */
#contact{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
}

#contact p{
  width: 900px;
  max-width: 90vw;
  text-align: center;
  line-height: 36px;
}

form{
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  width: 722px;
  max-width: 90vw;
}

input{
  height: 42px;
  width: 100%;
  margin: 5px 0 2rem 0;
  border: 1px solid #313131;
  padding-left: 10px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

textarea{
  height: 152px;
  width: 100%;
  margin: 5px 0 2rem 0;
  resize: none;
  border: 1px solid #313131;
  padding: 10px;
  font-family: "Lato", sans-serif;
  font-size: 16px;

}

label{
  text-align: left;
  font-family: "bigmoore", serif; 
  font-weight: 400; 
  font-style:italic;
  font-size: 22px;
}




/* Footer */

footer {
  background-color: #BFBFA3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 4rem 2rem;
}

#logo-3{
  height: 138px;
  width: 280px;
  max-width: 90vw;
  object-fit: contain;
  
}

.social{
  height: 58px;
  width: 58px;
  margin: 10px;
}

#frantech{
  width: 200px;
}

/* Fonts */


@font-face {
  font-family: "Sfizia";
  src: local("Sfizia"),
   url("./Assets/fonts/sfizia.ttf") format("truetype");
 }

@font-face {
  font-family: "Lato";
  src: local("Lato"),
   url("./Assets/fonts/Lato.ttf") format("truetype");
}

/* Responsive design */

@media only screen and (max-width: 1500px) {
  #services-right-container {
    text-align: center;
  }
  #services-right-container p {
    margin: 1rem auto;
    text-align: left;
  }
}


@media only screen and (max-width: 900px) {
  #initial-logo {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  header nav {
    display: none;
  }

  header{
    justify-content: center;
  }

  #brainteaser{
    display: none;
  }

  #services-right-container {
    text-align: left;
  }

  #services, .service-container{
    background-color: white;
  }

  .service-container{
    padding: 0;
  }

  #elevate{
    display: none;
  }

  footer{
    justify-content: center;
  }
  footer > * {
    margin: 1rem 0;
  }

  #logo-name{
    width: 60vw;
    height: auto;
    object-fit: cover;
  }

  h2{
    max-width: 95vw;
    font-size: 42px;
  }

  #gallery{
    display: none;
  }


  .social{
    width: 50px;
    height: 50px;
  }

  #services h3 {
    margin: 10px 0;
  }

  .mobile-img{
    display: block;
    width: 100vw;
  }
  #elevate-mobile{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 4rem 0;
  }
  #elevate-mobile p{
    width: 70%;
    font-size: 36px;
  }

  #hero-img{
    background: url('./Assets/hero.webp');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }
  #burger-menu {
    position: absolute;
    top: 25px;
    right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 15px;
    cursor: pointer;
  }
  
  #burger-top,
  #burger-bottom {
    width: 30px;
    height: 1px;
    background-color: #313131;
    transition: transform 0.5s;
  }
  
  #burger-menu.open #burger-top {
    transform: translateY(8px) rotate(45deg);
  }
  
  #burger-menu.open #burger-bottom {
    transform: translateY(-6px) rotate(-45deg);
  }
}